<template>
  <aheader/>
  <section class="section">
    <div class="error-message notification is-danger is-light" v-show="errorMsg != ''" v-html="errorMsg"></div>
    <div class="columns ">
      <div class="column is-6 is-offset-3">
        <div class="box" style="background: #FCFAF2">
          <div class="m-4">
            <span class="icon is-large">
              <i class="fa-solid fa-2x fa-circle-user"></i>
            </span>
          </div>
          <div class="field">
            <label class="label">メールアドレス</label>
            <div class="columns">
              <div class="column is-10 is-offset-1">
                <div class="control has-icons-left has-icons-right">
                  <input ref="email" class="input" type="email" placeholder="メールアドレス" v-model="mailAddress">
                  <span class="icon is-small is-left m-3">
                    <i class="fas fa-envelope"></i>
                  </span>
                  <!--
                  <span class="icon is-small is-right m-3">
                    <i class="fas fa-check"></i>
                  </span>
                -->
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="columns">
              <div class="column is-10 is-offset-1">
                <label class="label">パスワード</label>
                <div class="control has-icons-left">
                  <input class="input" type="password" placeholder="パスワード" v-model="password">
                  <span class="icon is-small is-left m-3">
                    <i class="fas fa-lock"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <button class="button is-success" @click="doLogin()">
            <span>
              ログイン
            </span>
            <span class="icon is-small">
              <i class="fa-solid fa-right-to-bracket"></i>
            </span>
          </button>
          <div class="mt-5"><a class="has-text-info-dark" @click="pushPage('forget_pass')">パスワードを忘れた場合</a></div>
          <div><a class="has-text-info-dark" @click="pushPage('regist')">新規会員登録される場合</a></div>
        </div>
      </div>
    </div>
  </section>
  <afooter/>
</template>
<script>
import { getAuth, signInWithEmailAndPassword, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

export default {
  data() {
    return {
      mailAddress:"",
      password:"",
      errorMsg:""
    }
  },
  mounted() {
    this.scrollTop()
    this.$refs.email.focus();
  },
  methods: {
    async sign_in() {
      const auth = getAuth(this.$firebase);
      await setPersistence(auth, browserSessionPersistence);
      const userCredential = await signInWithEmailAndPassword(auth, this.mailAddress, this.password);
      //console.log(userCredential)
      const docsnap = await getDoc(doc(this.$firestore_db, 'users', userCredential.user.uid));
      if (docsnap.exists()) {
        const data = docsnap.data();
        //console.log(data);
        this.$store.commit('set4login', data);
      }
    },
    doLogin() {
      //console.log(this.mailAddress)
      //console.log(this.password)
      this.errorMsg = ""
      this.sign_in()
      .then(()=>{
        this.$router.push('/index');
      })
      .catch((err)=>{
        console.log(err);
        this.addErrMsg("メールアドレスもしくはパスワードが違います。ご確認ください。");
      })
    },
    addErrMsg(msg) {
      if (this.errorMsg != "") this.errorMsg += "<br/>";
      this.errorMsg = this.errorMsg + msg;
    },
    pushPage(page) {
      this.$router.push(page)
    },
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      })
    }
  }
}
</script>
<style scoped>
h4 {
  margin-top: 2%;
}
input {
  margin: 2%;
  font-size: 18px;
}
.button1 {
  align-items: center;
  margin: 2%;
  color: #FCFAF2;
  background-color: #C21732;
  border: 3px solid #C21732;
  border-radius: 5px;
  cursor:pointer;
}
/*  box-sizing: border-box;
  border: none;*/
</style>
